<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col md="4" lg="4" xl="3" class="d-none d-md-block position-relative overflow-hidden pa-0">
          <div class="auth-illustrator-wrapper d-flex justify-center">
            <div class="d-flex align-center px-10 px-lg-12 flex-column justify-center flex-grow-1">
              <v-img
                contain
                max-width="453px"
                width="100%"
                class="flex-grow-0 mb-5"
                :src="require(`@/assets/images/login/login_illustration_experts.svg`)"
              ></v-img>
              <p class="text-center accent--text text-xl">bAV Portal</p>
            </div>
          </div>
        </v-col>

        <v-col md="1" lg="1" xl="1">
          <div v-if="$route.query.firstname && $route.query.employer" class="px-4 py-4">
            <img
              style="width: 180px"
              :src="`https://api.degura.de/2.0/qr-code/https://app.degura.de/onboarding?firstname=${$route.query.firstname}&token=${$route.query.token}&employer=${$route.query.employer}`"
              alt="QR Code"
            />
            <br />
            <div class="text--bold" style="white-space: nowrap">
              {{ $t('onSmartphone') }}
            </div>
          </div>
        </v-col>

        <v-col md="7" lg="7" xl="8" cols="12" class="d-flex auth-bg-v2 pb-0 flex-column">
          <div class="d-flex flex-column">
            <AppBarI18n />
          </div>
          <div class="d-flex flex-grow-1 align-center">
            <v-card class="auth-card" color="transparent">
              <v-card-title class="d-flex align-center justify-center py-7">
                <v-img :src="appLogo" max-width="375px" alt="logo" contain></v-img>
              </v-card-title>
              <v-card-text>
                <p class="font-weight-bold text--secondary mb-2 text-left text-xl">
                  {{ $route.query.companyAccessId ? $t('welcomeChangePwTitle') : $t('headlineChangePassword') }}
                </p>
                <p class="mb-0 text-left text-sm">
                  {{ $route.query.companyAccessId ? $t('welcomeChangePwDescription') : $t('errorPasswordTooShort') }}
                </p>
              </v-card-text>

              <!-- login form -->
              <v-card-text>
                <v-form ref="changePwForm" @submit.prevent="confirmPwChanging">
                  <v-text-field
                    v-model="password"
                    outlined
                    :rules="[validators.required, validators.simplePasswordValidator]"
                    :type="isPasswordVisible ? 'text' : 'password'"
                    :label="$t('formLabelNewPassword')"
                    :placeholder="$t('formLabelNewPassword')"
                    :append-icon="isPasswordVisible ? icons.mdiEye : icons.mdiEyeOutline"
                    hide-details="auto"
                    class="mb-3 required"
                    @click:append="isPasswordVisible = !isPasswordVisible"
                  >
                    <template #message="{ message }">
                      {{ $t(message) }}
                    </template>
                  </v-text-field>

                  <v-text-field
                    v-model="repeatPassword"
                    outlined
                    :rules="[validators.required, validators.passwordConfirmationValidator]"
                    :type="isPasswordVisible ? 'text' : 'password'"
                    :label="$t('formLabelRepeatPassword')"
                    :placeholder="$t('formPlaceholderRepeatPassword')"
                    :append-icon="isPasswordVisible ? icons.mdiEye : icons.mdiEyeOutline"
                    hide-details="auto"
                    class="mb-4 required"
                    @click:append="isPasswordVisible = !isPasswordVisible"
                  >
                    <template #message="{ message }">
                      {{ $t(message) }}
                    </template>
                  </v-text-field>

                  <v-btn block depressed color="primary" type="submit" class="mt-4">
                    {{ $t('buttonConfirm') }}
                  </v-btn>
                </v-form>
              </v-card-text>

              <!-- support  -->
              <v-card-text class="text-center flex-wrap mt-3 mb-3">
                <span class="mb-2 text-sm"> {{ $t('loginProblem') }} </span><br />
                <span>
                  <button
                    :disabled="!acceptedCookies"
                    class="support-link primary--text text-sm mt-1"
                    @click.prevent="openUserlike"
                  >
                    {{ $t('loginSupportLink') }}
                  </button>
                </span>
              </v-card-text>
            </v-card>
          </div>
          <Footer />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { required, passwordValidator, simplePasswordValidator } from '@core/utils/validation';
import { mdiEyeOutline, mdiEyeOffOutline, mdiEye } from '@mdi/js';
import { ref, getCurrentInstance, computed, onMounted } from '@vue/composition-api';
import themeConfig from '@themeConfig';
import { login } from '@/api/fusion-auth';
import { LOGIN_ACTION, CHANGE_PASSWORD_REQUIRED } from '../../store/modules/auth/actions';
import Footer from '@/components/footer/Footer.vue';
import AppBarI18n from '@/layouts/components/app-bar/AppBarI18nLoggetOut.vue';

export default {
  components: {
    Footer,
    AppBarI18n,
  },
  setup() {
    const vm = getCurrentInstance().proxy;
    const changePwForm = ref(null);
    const isPasswordVisible = ref(false);
    const repeatPassword = ref('');

    const email = ref('');
    const password = ref('');
    const acceptedCookies = ref(vm.$store.getters.necessaryCookies);
    const handleSubmit = () => {
      // const isFormValid = changePwForm.value.validate()
      //
      // if (!isFormValid) return
      //
      // vm.$store.dispatch(LOGIN_ACTION, {
      //     email: email.value,
      //     password: password.value
      // })
    };
    const openUserlike = () => {
      vm.$userlike.userlikeStartChat();
    };
    const passwordConfirmationValidator = (c) => password.value === c || vm.$t('errorPasswordMatch');
    const confirmPwChanging = () => {
      const isFormValid = changePwForm.value.validate();
      if (!isFormValid) return;
      vm.$store.dispatch(CHANGE_PASSWORD_REQUIRED, {
        password: password.value,
        token: vm.$route.query.token,
      });
      changePwForm.value.reset();
    };

    onMounted(() => {
      vm.$store.commit('setLoginError', null);
      const { token, firstname, employer } = vm.$route.query;
      if (token) {
        let redirectUrl = `https://app.degura.de/onboarding?token=${token}`;
        if (firstname) {
          redirectUrl += `&firstname=${firstname}`;
        }
        if (employer) {
          redirectUrl += `&employer=${employer}`;
        }
        window.location.href = redirectUrl;
      }
    });

    return {
      isPasswordVisible,
      email,
      password,
      repeatPassword,
      changePwForm,
      handleSubmit,
      confirmPwChanging,
      loginError: computed(() => vm.$store.getters.loginError),
      acceptedCookies,
      openUserlike,

      // Icons
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiEye,
      },

      validators: {
        required,
        passwordValidator,
        simplePasswordValidator,
        passwordConfirmationValidator,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    };
  },
};
</script>

<style lang="scss" scoped></style>

// import { canNavigate } from '@/plugins/acl/routeProtection'
import Vue from 'vue';
import VueRouter from 'vue-router';

import adminRoutes from './admin';
import globalAdminRoutes from './global-admin';
import employeeRoutes from './employee';
import commonRoutes from './common';

import store from '@/store';
import { ROLE_EMPLOYEE, ROLE_ADMIN, HR_ROLES } from '../constants';

Vue.use(VueRouter);

const DEFAULT_PAGE_TITLE = 'Degura bAV Portal';

const routes = [
  {
    path: '/',
    redirect: (to) => {
      const { admin } = to.query;
      if (admin) {
        return { name: 'auth-login', query: to.query };
      }
      if (store.getters.accessToken && store.getters.activeRole === ROLE_ADMIN) {
        return { name: 'companies' };
      }

      window.location.href = 'https://app.degura.de';
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    beforeEnter: (to, from, next) => {
      const { admin } = to.query;
      if (admin) {
        next();
        return;
      }
      window.location.href = 'https://app.degura.de';
    },
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
      titleKey: 'loginTitle',
    },
  },
  {
    path: '/willkommen/:companyAccessId',
    name: 'welcome-login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
      titleKey: 'pageTitleWelcome',
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/auth/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
      titleKey: 'forgotPwTitle',
    },
  },
  {
    path: '/datenschutzerklaerung-akzeptieren',
    name: 'accept-privacy-policy',
    component: () => import('@/views/auth/AcceptPrivacyPolicy.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
      titleKey: 'pageTitleAcceptPrivacyPolicy',
    },
  },
  {
    path: '/passwort-aendern',
    name: 'change-password',
    component: () => import('@/views/auth/ChangePassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
      titleKey: 'pageTitleChangePassword',
    },
  },
  {
    path: '/2fa',
    name: '2fa-init',
    component: () => import('@/views/2fa/TwoFactorInit.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      titleKey: 'pageTitle2faSetup',
    },
  },
  {
    path: '/2fa-login',
    name: '2fa-login',
    component: () => import('@/views/2fa/TwoFactorLogin.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      titleKey: 'pageTitle2faVerification',
    },
  },
  ...adminRoutes,
  ...employeeRoutes,
  ...commonRoutes,
  ...globalAdminRoutes,

  {
    path: '*',
    redirect: 'error-404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters.loggedIn;
  const isAdmin = HR_ROLES.includes(store.getters.activeRole);
  const isGlobalAdmin = store.getters.activeRole === ROLE_ADMIN;
  const isEmployee = store.getters.activeRole === ROLE_EMPLOYEE;

  if (to.meta.resource !== 'Public' /*! canNavigate(to) */) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login', query: { marketplace: to.query.marketplace } });
  }

  if (to.meta.resource === 'Admin') {
    if (!isAdmin) return next({ name: 'error-404' });
  }

  if (to.meta.resource === 'Employee') {
    if (!isEmployee) return next({ name: 'error-404' });
  }

  if (to.meta.resource === 'GlobalAdmin') {
    if (!isGlobalAdmin) return next({ name: 'error-404' });
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/');
  }

  if (to.name === 'meine-bav') {
    const hasQueryParams = (route) => {
      return !!Object.keys(route.query).length;
    };
    if (!hasQueryParams(to) && hasQueryParams(from)) {
      next({ name: to.name, query: from.query });
    } else {
      next();
    }
  }

  // Set page title
  document.title = to.meta.titleKey ? router.app.$t(to.meta.titleKey) : DEFAULT_PAGE_TITLE;

  // Reset all overlays
  store.commit('app/TOGGLE_CONTENT_OVERLAY', false);

  return next();
});

export default router;

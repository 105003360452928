import axios from '@/plugins/axios';

export const acceptTerms = () => {
  return axios.patch(`/authentication/user/me`, { termsOfUseAcceptedAt: new Date().toISOString() });
};

export const getEmployeeById = (id) => {
  return axios.get(`/employees/${id}`);
};

export const createEmployee = (payload) => {
  return axios.post('/employees', payload);
};

export const deleteEmployee = (id) => {
  return axios.delete(`/employees/${id}`);
};

export const getContractByEmployeeId = async (id) => {
  const response = await axios.get(`/contracts/employees/${id}`);

  return response.data[0];
};

export const updateEmployeePersonalData = (id, payload) => {
  return axios.put(`/employees/${id}/personal`, payload);
};

export const updateEmployeeTaxData = (id, payload) => {
  return axios.put(`/employees/${id}/tax`, payload);
};

export const employeeOptOut = async (employeeId, payload) => {
  const contractResponse = await getContractByEmployeeId(employeeId);

  return axios.post(`/contracts/${contractResponse.id}/opt-out`, payload);
};

export const updateEmployeeContractStatus = async (employeeId, contractStatus) => {
  const contractResponse = await getContractByEmployeeId(employeeId);

  return axios.post(`/contracts/${contractResponse.id}/update-status`, { contractStatus });
};

export const requestContract = async (employeeId, payload, type = 'request-contract') => {
  const contractResponse = await getContractByEmployeeId(employeeId);

  const adjustedPayload = {
    ...payload,
    employeeContributionNet: payload.totalContribution,
    beneficiaryDetails: {
      beneficiaryFirstName: '-',
      beneficiaryLastName: '-',
      beneficiaryDateOfBirth: '1970-01-01',
      beneficiaryStreetAddress: '-',
      beneficiaryPostalCode: '-',
      beneficiaryCity: '-',
      beneficiaryCountry: 'DE',
      ...payload.beneficiaryDetails,
    },
    personalDetails: {
      ...payload.personalDetails,
      biologicalSex: payload.personalDetails.salutation === 'SALUTATION_MR' ? 'MALE' : 'FEMALE',
    },
  };

  return axios.post(`/contracts/${contractResponse.id}/${type}`, adjustedPayload);
};
